body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.animate-overlay {
  animation-name: overlay;
  animation-duration: 1s;
  opacity: 0.8;
}

.formFooter, .formFooter-heightMask, .branding21 {
  display: none!important;
}

ul, ol {
  list-style: initial;
}

.article a {
  text-decoration: underline;
  color: #0000EE;
}